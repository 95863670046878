import React from 'react';
import algoliasearch from 'algoliasearch';
import dynamic from 'next/dynamic';
import { InstantSearch, connectSearchBox } from 'react-instantsearch-dom';
import { SearchState } from '@/utils/searchState';

const MainComponent = dynamic(() => import(`../MainComponent/MainComponent`));
interface Props {
  resetPage: () => void;
  searchState: SearchState;
  onSearchStateChange: (nextState: SearchState) => void;
  resultsState: any;
}

export const searchClient = algoliasearch(
  process.env.ALGOLIA_APPLICATION_ID || ``,
  process.env.ALGOLIA_API_KEY || ``,
);

export const indexName = `verified-jobs`;

//prevent front-end algolia request for single job page
const algoliaClient = {
  ...searchClient,
  search(requests: any) {
    if (requests[0].params?.query?.length) {
      return Promise.resolve();
    } else {
      return searchClient.search(requests);
    }
  },
};

const VirtualSearchBox = connectSearchBox(() => null);

const App = ({ resetPage, ...props }: Props) => {
  return (
    <InstantSearch
      searchClient={algoliaClient}
      indexName={indexName}
      {...props}
    >
      <VirtualSearchBox />
      <MainComponent resetPage={resetPage} />
    </InstantSearch>
  );
};

export default App;
